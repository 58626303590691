<template>
  <div class="concept-detail">
    <el-row>
      <ai-breadcrumb />
    </el-row>
    <div class="summary-content">
      <el-row>
        <el-col :span="8">
          <div class="summary-title summary-text">
            {{ $t("gpt.desc") }} :
          </div>
          <div class="summary-text">{{ this.task && this.task.desc ? this.task.desc : "-" }}</div>
        </el-col>
        <el-col :span="8">
          <div class="summary-title summary-text">Lora:</div>
          <div class="summary-text">
            <el-popover placement="right" trigger="click">
              <p style="margin: 5px;" v-for="key in this.task.loraInfos" :key="key.id">{{ key.name }}</p>
              <el-button slot="reference" size="mini">details ({{ this.task.loraInfos.length }})</el-button>
            </el-popover>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="summary-title summary-text">
            {{ $t("gpt.status") }} :
          </div>
          <el-tag :type="this.task | statusFilter" effect="dark" size="small">{{
            this.task
              ? this.task.status === "waiting"
                ? $t("tagger.waiting")
                : this.task.status === "running"
                  ? $t("tagger.running")
                  : this.task.status === "done"
                    ? $t("tagger.done")
                    : this.task.status === "abort"
                      ? $t("tagger.abort")
                      : this.task.status === "error"
                        ? $t("tagger.error")
                        : this.task.status
              : "-"
          }}</el-tag>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="summary-title summary-text">
            {{ $t("gpt.execAt") }} :
          </div>
          <div class="summary-text">
            {{ task && task.execAt ? task.execAt : "" | displayTime() }}
          </div>
        </el-col>
        <el-col :span="8">
          <div class="summary-title summary-text">
            {{ $t("gpt.finishAt") }} :
          </div>
          <div class="summary-text">
            {{ task && task.finishAt ? task.finishAt : "" | displayTime() }}
          </div>
        </el-col>
        <el-col :span="8">
          <div class="summary-title summary-text">
            {{ $t("common.createAt") }} :
          </div>
          <div class="summary-text">
            {{ task && task.createdAt ? task.createdAt : "" | displayTime() }}
          </div>
        </el-col>
      </el-row>
    </div>

    <div v-if="showResults" class="image-content">
      <p v-if="loading" class="data-loading">
        <i class="el-icon-loading" />&nbsp;{{ $t("common.loading") }}...
      </p>
      <p v-else-if="!tableData || tableData.length === 0" class="data-loading">
        {{ notice ? notice : $t("common.noMoreData") }}
      </p>
      <el-row v-else :gutter="10">
        <el-col class="data-block" :lg="{ span: '5-24' }" v-for="item in tableData" :key="item.url">
          <div class="data-block-content">
            <el-popconfirm :title="$t('gpt.comfirmReport')" @confirm="handleBlock(item.id)">
              <template #reference>
                <el-tooltip class="item" effect="dark" :content="$t('gpt.report')" placement="top">
                  <el-button class="block-button" type="danger" plain circle>
                    <svg-icon icon-class="block-btn" />
                  </el-button>
                </el-tooltip>
              </template>
            </el-popconfirm>
            <el-image class="content-image" :src="item.url | imageUrl(false)"
              :preview-src-list="[imageUrl(item.url, false)]" fit="contain" />
            <div class="data-block-text">
              <el-row class="content-line">
                # {{ item.desc }}
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <running v-if="task && !showResults" :current="task" :abortCallback="fetchData"></running>
  </div>
</template>

<script>
import { fetchOneTask, fetchImages, blockImage, fetchTargets } from "@/api/gpt";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { imageUrl } from "@/filters";
import Running from "@/components/gpt/Running";
import { elapsedSeconds } from '@/utils';

export default {
  components: {
    AiBreadcrumb,
    Running,
  },
  data() {
    return {
      task: {
        loraInfos: []
      },
      tableData: [],
      loading: false,
      notice: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  filters: {
    statusFilter(task) {
      if (!task) {
        return "info";
      }
      const statusMap = {
        waiting: "warning",
        running: "",
        done: "success",
        abort: "info",
        error: "danger",
      };
      return statusMap[task.status];
    },
  },
  methods: {
    fetchData() {
      if (this.$route.params && this.$route.params.id) {
        fetchOneTask(this.$route.params.id).then((response) => {
          if (response.success) {
            let task = response.result;
            task.elapsedSeconds = task.execAt ? elapsedSeconds(task.execAt) : 0;
            this.task = task;
            if (
              response.result.status === "done" ||
              response.result.status === "error" ||
              response.result.status === "abort"
            ) {
              clearTimeout(this.timer);
              fetchTargets({ limit: -1, group: "concept-lora" }).then(response => {
                if (response.success) {
                  let loraList = response.result && response.result.items ? response.result.items : [];
                  this.task.loraInfos.forEach((e, i) => {
                    for (let j = 0; j < loraList.length; j++) {
                      if (loraList[j].id === e.id) {
                        let loraInfo = this.task.loraInfos[i];
                        loraInfo.name = loraList[j].name;
                        this.$set(this.task.loraInfos, loraInfo, i)
                      }
                    }
                  });
                }
              })
              this.fetchTaskImages();
            } else {
              this.timer = setTimeout(this.fetchData, 4000);
            }
          }
        });
      }
    },
    fetchTaskImages() {
      fetchImages({ category: "concepter", taskId: this.task.id }).
        then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
          }
        });
    },
    handleBlock(id) {
      blockImage(id).then((response) => {
        if (response.success) {
          this.$notify({
            title: this.$t("common.saveSucc"),
            type: "success",
          });
          this.fetchTaskImages();
        }
      });
    },
    imageUrl(url, isExternal) {
      return imageUrl(url, isExternal);
    },
  },
  computed: {
    showResults() {
      return (
        this.task &&
        (this.task.status === "done" ||
          this.task.status === "abort" ||
          this.task.status === "error")
      );
    },
  }
};
</script>

<style lang="scss" scoped>
.concept-detail {
  .el-col-lg-5-24 {
    width: 1/5 * 100%;
  }

  .summary-content {
    color: #606266;
    background-color: #fff;
    padding: 2px 10px;
    margin-bottom: 10px;

    .el-row {
      margin: 10px 0;
    }

    .summary-title {
      font-weight: bold;
      margin-right: 8px;
    }

    .summary-text {
      display: inline;
      vertical-align: middle;
    }
  }

  .image-content {
    overflow: hidden;
    font-size: small;
    margin-bottom: -10px;
    text-align: left;

    .data-loading {
      text-align: center;
      font-size: 14px;
      color: #606266;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .data-block {
      margin-bottom: 10px;

      .data-block-content {
        background-color: #fff;
        padding-bottom: 5px;
        position: relative;

        .block-button {
          position: absolute;
          z-index: 1;
          top: 5px;
          right: 5px;
          font-size: xx-large;
          padding: 4px 2px 0;
          border: none;
        }

        .content-image {
          width: 100%;
          height: 0;
          padding-bottom: 100%;

          /deep/ img {
            position: absolute;
            margin-bottom: 5px;
          }
        }

        .data-block-text {
          margin-top: 5px;
          text-align: center;
        }

        .content-line {
          font-weight: bold;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: rgba(0, 0, 0, 0.65);

          .content-label {
            color: #a9a9a9;
            margin-right: 5px;
            line-height: 27px;
          }

          .content-link {
            margin-top: 5px;
            margin-right: 5px;
            line-height: 27px;
          }
        }
      }
    }
  }
}
</style>